import * as React from "react";
import { Formik, Field, Form } from "formik";
import { TextFormField } from "../../components/textform/TextFormField.tsx";
import * as yup from "yup";
import { AddUsers } from "../../services/user.js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import YupPassword from "yup-password";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import MultiSelectFormField from "../../components/multiselectform/MultiSelectFormField.tsx";
import CircularProgress from "@mui/material/CircularProgress";
import checkRole from "../../helpers/check_role.js";
import { useFormikContext } from "formik";

YupPassword(yup);

export default function CreateUser({ info }) {
  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const blankProfilePhoto = require("../../assets/blank-profile-picture.png");
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  let options = [{ value: "ROLE_USER", label: "User" }];

  if (checkRole(info.role, "ROLE_ADMIN")) {
    options.unshift(
      { value: "ROLE_ADMIN", label: "Admin" },
      { value: "ROLE_SUPER_USER", label: "Super User" }
    );
  }

  const form = [
    {
      name: "first_name",
      label: "First Name",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      disabled: false,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "email",
      label: "Email",
      disabled: true,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "password",
      label: "Password",
      disabled: true,
      component: TextFormField,
      type: "password",
      style: {
        width: "49%",
      },
    },
    // {
    //   name: "confirm_password",
    //   label: "Confirm Password",
    //   disabled: false,
    //   component: TextFormField,
    //   type: "password",
    //   style: {
    //     width: "49%",
    //   },
    // },
    {
      name: "company_name",
      label: "Company",
      disabled: true,
      component: TextFormField,
      style: {
        width: "49%",
      },
    },
    {
      name: "role",
      label: "Role",
      disabled: false,
      multiple: true,
      component: MultiSelectFormField,
      select: true,
      options: options,
      style: {
        width: "49%",
      },
    },
  ];

  const validation = yup.object().shape({
    // name: yup.string().required("Full Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email"
      ),
    password: yup
      .string()
      .required("Password is required")
      .minLowercase(1, "password must contain at least 1 lower case letter")
      .minUppercase(1, "password must contain at least 1 upper case letter")
      .minSymbols(1, "password must contain at least 1 special character"),
    // confirm_password: yup
    //   .string()
    //   .required("Confirm Password is required")
    //   .oneOf([yup.ref("password"), null], "Passwords must match"),
    company_name: yup.string().required("Company is required"),
    role: yup.array().min(1, "At least one role is required"),
  });

  const handleFileRead = async (event) => {
    return await convertBase64(event);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleError = (description) => {
    setError(true);
    setErrorDescription(description);
  };

  const submitForm = async (values) => {
    let photo = blankProfilePhoto;

    if (file) {
      photo = await handleFileRead(file);
    }

    const data = {
      name: values.first_name + " " + values.last_name,
      email: values.email,
      password: values.password,
      role: values.role,
      company_name: values.company_name,
      photo: photo,
      changed_password: true,
    };

    var jsonData = JSON.stringify(data);
    try {
      setLoading(true);
      const content = await AddUsers(jsonData);

      if (content.error) {
        handleError(content.error);
      } else if (content.status === "OK") {
        navigate("/user");
      }
    } catch {
      handleError("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    document.querySelector("#file-input").click();
  };

  const MyForm = () => {
    const { values, setFieldValue } = useFormikContext();

    React.useEffect(() => {
      if (values.first_name && values.last_name) {
        setFieldValue(
          "email",
          `${values.first_name}.${values.last_name}@ro.yusen-logistics.com`.toLowerCase()
        );
      }
    }, [values.first_name, values.last_name, setFieldValue]);

    React.useEffect(() => {
      if (values.first_name) {
        setFieldValue(
          "password",
          `${values.first_name
            .charAt(0)
            .toUpperCase()}${values.first_name.slice(1)}!`
        );
      }
    }, [values.first_name, setFieldValue]);

    return (
      <Form
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="form-position">
          {form.map((snapshot, key) => {
            return <Field {...snapshot} key={key} />;
          })}
        </div>
        <button className="submit-form" type="submit">
          {loading ? <CircularProgress size={24} /> : "Create User"}
        </button>
      </Form>
    );
  };

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      >
        {error && (
          <div className="error-container">
            <span className="error-text">{errorDescription}</span>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="upload-container">
          <div className="input-circle" onClick={handleButtonClick}>
            <input
              type="file"
              id="file-input"
              name="photo"
              className="input-field"
              accept="image/*"
              onChange={(event) => {
                setFile(event.target.files[0]);
              }}
            />
            <div className="inner-circle">
              {file ? (
                <div className="inner-circle-img-container">
                  <img
                    src={URL.createObjectURL(file)}
                    alt="preview"
                    className="inner-circle-img"
                  />
                  <div className="add-photo-overlay">
                    <AddAPhotoIcon />
                    <span className="upload-text">Update photo</span>
                  </div>
                </div>
              ) : (
                <>
                  <img
                    src={blankProfilePhoto}
                    alt="default"
                    className="inner-circle-img"
                  />
                  <div className="add-photo-overlay">
                    <AddAPhotoIcon />
                    <span className="upload-text">Upload photo</span>
                  </div>
                </>
              )}
            </div>
          </div>
          <span className="upload-text-2">
            Allowed *.jpeg, *.jpg, *.png
            <br />
            max size of 3.1 MB
          </span>
        </div>

        <div className="form-container">
          <Formik
            validationSchema={validation}
            initialValues={{
              first_name: "",
              last_name: "",
              email: "@ro.yusen-logistics.com",
              password: "",
              confirm_password: "",
              company_name: "Yusen Logistics Romania",
              role: [],
              photo: "",
            }}
            onSubmit={(values) => {
              values.email =
                `${values.first_name}.${values.last_name}@ro.yusen-logistics.com`.toLowerCase();
              values.password = `${values.first_name
                .charAt(0)
                .toUpperCase()}${values.first_name.slice(1)}!`;
              submitForm(values);
            }}
          >
            <MyForm />
          </Formik>
        </div>
      </div>
    </div>
  );
}
