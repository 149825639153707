export default function checkRole(userRoles, minimumRole) {
  const roles = ["ROLE_USER", "ROLE_SUPER_USER", "ROLE_ADMIN"];
  if (!userRoles) {
    return false;
  }
  const minimumRoleIndex = roles.indexOf(minimumRole);

  if (minimumRoleIndex === -1) {
    throw new Error("Invalid role");
  }

  for (let role of userRoles) {
    const userRoleIndex = roles.indexOf(role);
    if (userRoleIndex >= minimumRoleIndex) {
      return true;
    }
  }

  return false;
}
