import React, { useEffect, useState } from "react";
import {
  DownloadExcelFile,
  GetStatisticExportDeclarations,
} from "../../services/management";
import useDebounce from "../../hooks/debounce";
import CustomTable from "../../components/table";
import PopSnackbar from "../../components/snackbar/Snackbar";
import moment from "moment";
import CustomCalendar from "../../components/calendar";
import { MenuItem, Select } from "@mui/material";

const columns = [
  {
    field: "id",
    headerName: "ID",
    headerAlign: "center",
    sortable: false,
  },
  {
    field: "created_at",
    headerName: "Created At",
    headerAlign: "center",
    sortable: false,
  },
  {
    field: "lrn",
    headerName: "LRN",
    headerAlign: "center",
    sortable: false,
  },
  {
    field: "country_of_export",
    headerName: "Country of Export",
    headerAlign: "center",
    sortable: false,
  },
  {
    field: "country_of_destination",
    headerName: "Country of Destination",
    headerAlign: "center",
    sortable: false,
  },
  {
    field: "total_amount_invoiced",
    headerName: "Total Amount Invoiced",
    headerAlign: "center",
    sortable: false,
  },
];

export default function StatisticsReports() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [successDescription, setSuccessDescription] = useState("");
  const [inputText, setInputText] = useState("");
  const [tableLoading, setTableLoading] = useState(true);
  const [downloadExcelLoading, setDownloadExcelLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().startOf("year").toDate(),
      endDate: moment().add(1, "days").toDate(),
      key: "selection",
    },
  ]);

  const templates = [{ templateId: 1, templateName: "Deichmann" }];
  const [selectedTemplate, setSelectedTemplate] = useState(
    templates[0].templateId
  );

  const debouncedSearchTerm = useDebounce(inputText, 500);

  async function getData(page, isLoading = true, searchTerm = "") {
    try {
      isLoading && setTableLoading(true);

      const startDate = moment(dateRange[0].startDate).format("YYYY-MM-DD");
      const endDate = moment(dateRange[0].endDate).format("YYYY-MM-DD");

      const content = await GetStatisticExportDeclarations(
        page + 1,
        searchTerm,
        startDate,
        endDate,
        selectedTemplate
      );
      if (content.page === 1) {
        setTotalCount(content.totalCount);
      }

      if (content.status !== "OK") {
        throw new Error(
          content.error || "Something went wrong, please try again later"
        );
      }

      const formattedDeclaratons = content.data.map((declaration) => {
        return {
          id: declaration.ID,
          created_at: moment(declaration.CreatedAt).format("DD-MM-YYYY HH:mm"),
          lrn: declaration.lrn,
          country_of_export: declaration.country_of_export,
          country_of_destination: declaration.country_of_destination,
          total_amount_invoiced: declaration.total_amount_invoiced,
        };
      });
      setData(formattedDeclaratons);
    } catch (error) {
      handleStatus(error.message, setError, setErrorDescription);
    } finally {
      isLoading && setTableLoading(false);
    }
  }

  const handleStatus = (description, setStatus, setDescription) => {
    setStatus(true);
    setDescription(description);
    setTimeout(() => {
      setStatus(false);
      setDescription("");
    }, 5000);
  };

  const handleChangePage = async (event, newPage) => {
    await getData(newPage, true, debouncedSearchTerm);
    setPage(newPage);
  };

  async function handleDownloadExcel(startDate, endDate, templateId) {
    try {
      setTableLoading(true);
      await DownloadExcelFile(startDate, endDate, templateId);
      handleStatus(
        "Fisierul a fost descarcat cu succes.",
        setSuccess,
        setSuccessDescription
      );
    } catch {
      handleStatus(
        "Something went wrong, please try again later.",
        setError,
        setErrorDescription
      );
    } finally {
      setTableLoading(false);
    }
  }

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3 || debouncedSearchTerm.length === 0) {
      setPage(0);
      getData(0, true, debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, dateRange, selectedTemplate]);

  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: "1vw" }}>
      <Select
        value={selectedTemplate}
        onChange={(e) => setSelectedTemplate(e.target.value)}
        sx={{
          fontFamily: "SeagoeUI",
          fontSize: "0.7vw",
          backgroundColor: "#8884d8",
          color: "#fff",
          borderRadius: "0.5vw",
          width: "7vw",
          height: "1.5vw",
          "& .MuiSelect-icon": {
            color: "black",
          },
          "&:hover": {
            backgroundColor: "#7a73d2",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&:focus": {
            outline: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        {templates.map((template) => (
          <MenuItem key={template.templateId} value={template.templateId}>
            {template.templateName}
          </MenuItem>
        ))}
      </Select>
      <CustomCalendar dateRange={dateRange} setDateRange={setDateRange} />

      {error && <PopSnackbar message={errorDescription} />}
      {success && <PopSnackbar message={successDescription} />}

      <CustomTable
        search={true}
        addNew={tableLoading ? false : true}
        addNewFunction={() =>
          handleDownloadExcel(
            moment(dateRange[0].startDate).format("YYYY-MM-DD"),
            moment(dateRange[0].endDate).format("YYYY-MM-DD"),
            selectedTemplate
          )
        }
        addNewTitle={"Download Excel Report"}
        inputText={inputText}
        setInputText={setInputText}
        columns={columns}
        tableLoading={tableLoading}
        data={data}
        page={page}
        handleChangePage={handleChangePage}
        totalCount={totalCount}
      />
    </div>
  );
}
