import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertModal({
  text,
  open,
  handleClose,
  handleYes,
  showYesButton = true,
  showNoButton = true,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{ fontSize: "1.2rem", fontFamily: "SeagoeUI" }}
          id="alert-dialog-title"
        >
          {text}
        </DialogTitle>
        <DialogActions>
          {showYesButton && <Button onClick={handleYes}>Yes</Button>}
          {showNoButton && (
            <Button onClick={handleClose} autoFocus>
              No
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
