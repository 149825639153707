import { getIn } from "formik";
import React, { useState } from "react";
import { TextField, MenuItem, IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const TextFormField: React.FC<any> = ({ field, form, ...props }) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      {...props}
      id="outlined-basic"
      variant="outlined"
      helperText={errorText}
      error={!!errorText || props.limit < field.value?.length}
      {...field}
      type={
        props.type === "password"
          ? showPassword
            ? "text"
            : "password"
          : props.type
      }
      InputProps={{
        sx: { borderRadius: 2 },
        style: { borderRadius: 4 },
        readOnly: props.readOnly,
        endAdornment:
          props.type === "password" ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleTogglePassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
    >
      {props.select &&
        props.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  );
};

// // ?????
// async function changeTextOrFile(e, form, field) {
//   const firstFile = e.target.files?.length > 0 && e.target.files[0];
//   if (field.name.includes(".fileContent")) {
//     console.log(field.name);
//     const document = await getBase64(firstFile);
//     const base64 = document.split("base64,")[1];
//     form.setFieldValue(field.name, base64);
//   } else if (field.name.includes(".fileName")) {
//     console.log(field.name);
//     form.setFieldValue(field.name, firstFile.name);
//   } else if (field.name.includes(".fileContentType")) {
//     form.setFieldValue(field.name, firstFile.type);
//   } else {
//     form.setFieldValue(field.name, e.target.value);
//   }
// }

// async function getBase64(file): Promise<string> {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => {
//       resolve(reader.result as string);
//     };
//     reader.onerror = reject;
//   });
// }
