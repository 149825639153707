import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  LinearProgress,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import Input from "@mui/material/Input";
import SearchCustomTable from "./search";
import AddIcon from "@mui/icons-material/Add";
import { Maximize2 } from "react-feather";
import AlertModal from "../modal/alert";

export default function CustomTable({
  search,
  inputText,
  setInputText,
  columns,
  tableLoading,
  data,
  page,
  handleChangePage,
  totalCount,
  addNew,
  addNewFunction,
  addNewTitle,
  openRows = {},
  setOpenRows,
  isCollapsableListItems = [],
  goodsItemColumns,
  editable = [],
  editValues,
  setEditValues,
  isRowColored = false,
  limitPerPage = 10,
}) {
  const indexOfTable = (index) => {
    return index + 1 + page * limitPerPage;
  };

  const toggleRowOpen = (index) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [index]: !prevOpenRows[index],
    }));
  };

  const handleEditChange = (id, field, value) => {
    setEditValues((prevEditValues) => ({
      ...prevEditValues,
      [id]: {
        ...prevEditValues[id],
        [field]: value,
      },
    }));
  };

  const rowColor = (val) => {
    switch (val.status) {
      case "PENDING":
        return { backgroundColor: "#F7F7AD" };
      case "SUCCESS":
        return { backgroundColor: "#B4F5A9" };
      case "ERROR":
        return { backgroundColor: "#F7ADAD" };
      default:
        return {};
    }
  };

  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
        boxShadow:
          "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
        borderRadius: "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
        }}
      >
        {search && (
          <SearchCustomTable
            inputText={inputText}
            setInputText={setInputText}
          />
        )}
        {addNew && (
          <div className="new-user-btn" onClick={addNewFunction}>
            <AddIcon style={{ fontSize: "1rem" }} />
            <div className="new-user-btn-text">{addNewTitle}</div>
          </div>
        )}
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, key) => (
                <TableCell
                  key={key}
                  sx={
                    {
                      backgroundColor: "rgb(244, 246, 248)",
                      color: "rgb(99, 115, 129)",
                      fontWeight: "bold",
                      fontSize: "14px",
                      fontFamily: "Public Sans, sans-serif",
                    } &&
                    column.width && { width: column.width }
                  }
                >
                  <p>{column.headerName}</p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableLoading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <div style={{ margin: 25, marginBlock: 30 }}>
                    <LinearProgress
                      color="inherit"
                      style={{
                        alignSelf: "center",
                        width: 200,
                        textAlign: "center",
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              data.map((val, key1) => (
                <React.Fragment key={key1}>
                  <TableRow
                    key={key1}
                    style={isRowColored ? rowColor(val) : {}}
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgb(244, 246, 248)",
                      },
                    }}
                  >
                    {columns.map((column, key2) => (
                      <TableCell
                        key={key2}
                        className="table-cell"
                        style={{
                          fontFamily: "SeagoeUI",
                          fontWeight:
                            column.field === "name" ? "600" : "normal",
                        }}
                      >
                        {column.renderCell ? (
                          column.renderCell({
                            value: val[column.field],
                            row: val,
                          })
                        ) : column.headerName === "Action" ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ flex: 1 }}>{val[column.field]}</span>
                            <Maximize2
                              onClick={() => toggleRowOpen(key1)}
                              size={20}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        ) : column.headerName === "Description" ? (
                          val.status !== "PENDING" && (
                            <div>
                              {openRows[key1] ? (
                                <div
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => toggleRowOpen(key1)}
                                >
                                  Hide Description
                                </div>
                              ) : (
                                <div
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => toggleRowOpen(key1)}
                                >
                                  View Description
                                </div>
                              )}
                            </div>
                          )
                        ) : column.isEditMode &&
                          editable.includes(val["id"]) ? (
                          <div>
                            <Input
                              style={{
                                fontFamily: "inherit",
                                fontSize: "inherit",
                              }}
                              value={
                                editValues[val["id"]] &&
                                editValues[val["id"]][
                                  `${val["id"]}_declarationUniqueKey_${column.field}`
                                ] !== undefined
                                  ? editValues[val["id"]][
                                      `${val["id"]}_declarationUniqueKey_${column.field}`
                                    ]
                                  : val[column.field]
                              }
                              onChange={(e) =>
                                handleEditChange(
                                  val["id"],
                                  `${val["id"]}_declarationUniqueKey_${column.field}`,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        ) : (
                          <span>{val[column.field]}</span>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  {openRows[key1] && val.actionDetails && (
                    <AlertModal
                      handleClose={() =>
                        setOpenRows({ ...openRows, [key1]: false })
                      }
                      open={openRows[key1]}
                      text={val.actionDetails}
                      showNoButton={false}
                      showYesButton={false}
                    />
                  )}
                  {openRows[key1] && val.description && (
                    <>
                      <div
                        className="errors-popup-overlay"
                        onClick={() =>
                          setOpenRows({ ...openRows, [key1]: false })
                        }
                      />
                      <div className="errors-popup fadeIn">
                        <button
                          onClick={() =>
                            setOpenRows({ ...openRows, [key1]: false })
                          }
                          className="errors-popup-x"
                        >
                          &times;
                        </button>
                        <pre>
                          {(() => {
                            try {
                              return JSON.stringify(
                                JSON.parse(val.description),
                                null,
                                2
                              );
                            } catch (e) {
                              return val.description;
                            }
                          })()}
                        </pre>
                      </div>
                    </>
                  )}
                  {isCollapsableListItems.includes(val.id) && (
                    <TableRow className="slideDown">
                      <TableCell colSpan={columns.length}>
                        <Box margin={1}>
                          <Typography variant="h6" gutterBottom component="div">
                            ITEM
                          </Typography>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                {goodsItemColumns.map((col, idx) => (
                                  <TableCell key={idx}>
                                    {col.headerName}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {val.goods_item.map((item, idx) => (
                                <TableRow key={idx}>
                                  {goodsItemColumns.map((col, idx) => (
                                    <TableCell key={idx}>
                                      {col.isEditMode &&
                                      editable.includes(val["id"]) ? (
                                        <div>
                                          <Input
                                            style={{
                                              fontFamily: "inherit",
                                              fontSize: "inherit",
                                            }}
                                            value={
                                              editValues[val["id"]] &&
                                              editValues[val["id"]][
                                                `${item.item_id}_itemUniqueKey_${col.field}`
                                              ] !== undefined
                                                ? editValues[val["id"]][
                                                    `${item.item_id}_itemUniqueKey_${col.field}`
                                                  ]
                                                : item[col.field]
                                            }
                                            onChange={(e) =>
                                              handleEditChange(
                                                val["id"],
                                                `${item.item_id}_itemUniqueKey_${col.field}`,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      ) : col.renderCell ? (
                                        col.renderCell({
                                          value: item[col.field],
                                          row: item,
                                        })
                                      ) : (
                                        item[col.field]
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))
            )}
          </TableBody>
        </Table>

        <TablePagination
          sx={{ display: "inline-flex" }}
          component="div"
          count={totalCount}
          rowsPerPage={limitPerPage}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[]}
          showFirstButton={true}
          showLastButton={true}
        />
      </TableContainer>
    </div>
  );
}
