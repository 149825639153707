import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";

export default function CustomCalendar({ dateRange, setDateRange }) {
  const [tempDateRange, setTempDateRange] = useState(dateRange);
  const [shownDate, setShownDate] = useState(dateRange[0].endDate);

  const handleSelect = (ranges) => {
    const newRange = [
      {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        key: "selection",
      },
    ];

    if (
      newRange[0].startDate !== tempDateRange[0].startDate ||
      newRange[0].endDate !== tempDateRange[0].endDate
    ) {
      setTempDateRange(newRange);
      if (ranges.selection.startDate !== ranges.selection.endDate) {
        setDateRange(newRange);
        setShownDate(newRange[0].endDate);
      }
    }
  };

  return (
    <div>
      <DateRangePicker
        ranges={tempDateRange}
        onChange={handleSelect}
        moveRangeOnFirstSelection={false}
        maxDate={addDays(new Date(), 1)}
        shownDate={shownDate}
      />
    </div>
  );
}
