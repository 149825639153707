import React, { useRef, useState } from "react";
import "../../assets/App.css";
import logoicon from "../../assets/logoicon-yusen.svg";
import AccountPopover from "./popover";
import ukflag from "../../assets/flag-united-kingdom.svg";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

export default function Header({ info }) {
  const [languageDropdown, setLanguageDropdown] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const iconButtonRef = useRef(null);

  const toggleLanguageDropdown = () => {
    setLanguageDropdown((prev) => !prev);
  };

  const handleOpenPopover = (event) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handleClosePopover = () => {
    setPopoverAnchor(null);
  };

  const handleFullscreen = () => {
    const element = document.documentElement;

    if (!isFullscreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    setIsFullscreen((prev) => !prev);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
        className="header"
      >
        <div className="header-left">
          <div style={{ height: 40, display: "grid" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 15,
              }}
            >
              <a href="/">
                <img src={logoicon} alt="Logo" height="40" />
              </a>
            </div>
          </div>
        </div>

        <a href="#0" className="mobile_btn" id="mobile_btn">
          <i className="fa fa-bars" />
        </a>
        <div className="header-right">
          <div className="header-right-left-side">
            <button
              id="fullscreen-btn"
              type="button"
              onClick={handleFullscreen}
            >
              <FullscreenIcon id="fullscreen-icon" />
              <div style={{ display: "flex", alignItems: "center" }}>
                {" "}
                <span
                  style={{
                    textDecoration: "underline",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    marginBottom: "2px",
                    fontFamily: "SeagoeUIBold",
                    letterSpacing: "0.4px",
                  }}
                >
                  {isFullscreen ? (
                    <p
                      style={{ fontFamily: "SeagoeUIBold", cursor: "pointer" }}
                    >
                      Minimize
                    </p>
                  ) : (
                    <p
                      style={{ fontFamily: "SeagoeUIBold", cursor: "pointer" }}
                    >
                      Maximize
                    </p>
                  )}
                </span>
              </div>
            </button>

            <div className="flags-menu" onClick={toggleLanguageDropdown}>
              <button id="dropdown-flag" type="button">
                <img
                  src={ukflag}
                  alt="uk-flag"
                  className="img-uk-flag"
                  width={30}
                  height={30}
                />
              </button>

              <ul className={`lang-menu ${languageDropdown ? "" : "hidden"}`}>
                <li>
                  <img src={ukflag} alt="uk-flag" className="img-uk-flag" />
                  English
                </li>
              </ul>
            </div>
          </div>
          <span
            className="job-role-text-header"
            // onClick={() => {
            //   iconButtonRef.current.click();
            // }}
          >
            {info.company_name} - {info.office_name}
          </span>

          <AccountPopover
            info={info}
            open={popoverAnchor}
            handleClose={handleClosePopover}
            handleOpen={handleOpenPopover}
            iconButtonRef={iconButtonRef}
          />
        </div>
      </div>
    </>
  );
}
