import React from "react";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchCustomTable({ inputText, setInputText }) {
  return (
    <div style={{ flex: 1 }}>
      <SearchIcon
        style={{
          color: "rgba(36, 36, 36, 0.1)",
          fontSize: "1.2rem",
          position: "absolute",
          left: "16px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      />

      <input
        type="text"
        placeholder="Search..."
        className="searchbar"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        style={{ paddingLeft: "35px" }}
      />
    </div>
  );
}
