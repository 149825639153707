import fetcher from "../helpers/fetcher";

export default async function FindMy() {
  let path = `/findMy`;

  let request = await fetcher(path, "GET", null, false);

  let content = request.json();

  return content;
}

export async function ReqLogin(body) {
  let path = `/login`;

  let request = await fetcher(path, "POST", body);

  let content = request.json();

  return content;
}

export async function ChangePassword(body) {
  let path = `/change-password`;

  let request = await fetcher(path, "POST", body);

  let content = request.json();

  return content;
}
