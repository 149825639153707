import React, { useState } from "react";
import { TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ChangePassword } from "../../services/auth";
import { useLocation } from "react-router-dom";

export default function UpdatePassword() {
  const [buttonLoading, setButtonLoading] = useState(false);

  const [oldPassword, setOldPassword] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState();

  const location = useLocation();
  const email = location.state?.email;

  function HasUpperCase(str) {
    return /[A-Z]/.test(str);
  }

  function HandleError(message) {
    setButtonLoading(false);
    setError(true);
    setErrorDescription(message);
    setTimeout(() => {
      setError(false);
      setErrorDescription(message);
    }, 3000);
  }

  function validatePassword(password) {
    if (password.length < 9) {
      return "Password must be at least 9 characters long.";
    }

    if (!/[A-Z]/.test(password)) {
      return "Password must contain at least one uppercase letter.";
    }

    if (!/[0-9]/.test(password)) {
      return "Password must contain at least one digit.";
    }

    if (!/[!@#$%^&*(),.?":{}|<>/]/.test(password)) {
      return "Password must contain at least one special character.";
    }

    return true;
  }

  async function SubmitChangePass() {
    setButtonLoading(true);

    const password_verification = validatePassword(password);
    const password_match = password === confirmPassword;

    if (password_verification !== true) {
      HandleError(password_verification);
    } else if (!password_match) {
      HandleError("Passwords do not match");
    } else {
      const body = {
        old_password: oldPassword,
        password,
        email,
      };
      const content = await ChangePassword(JSON.stringify(body));

      if (content.error) {
        HandleError(content.error);
      } else if (content.status === "OK") {
        localStorage.setItem("token", content.token);
        setButtonLoading(false);
        window.location.replace("/");
      } else {
        HandleError("Something went wrong, please try again later.");
      }
    }
  }

  return (
    <div className="login-body">
      <div className="loginbox2" style={{ zIndex: 1 }}>
        <h1 style={{ fontSize: "1.5rem", marginTop: 150 }}>
          Change your password to login
        </h1>
        <div className="signin-img"></div>
        <div className="login-right2">
          <div>
            <TextField
              style={{ width: "100%", marginTop: 10 }}
              variant="outlined"
              label={"Old Password"}
              type="password"
              onChange={(snap) => setOldPassword(snap.target.value)}
              InputProps={{
                style: {
                  borderRadius: 10,
                },
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  SubmitChangePass();
                }
              }}
            />
            <TextField
              style={{ width: "100%", marginTop: 10 }}
              variant="outlined"
              label={"New Password"}
              type="password"
              onChange={(snap) => setPassword(snap.target.value)}
              InputProps={{
                style: {
                  borderRadius: 10,
                },
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  SubmitChangePass();
                }
              }}
            />
            <TextField
              style={{ width: "100%", marginTop: 10 }}
              variant="outlined"
              label={"Confirm New Password"}
              type="password"
              onChange={(snap) => setConfirmPassword(snap.target.value)}
              InputProps={{
                style: {
                  borderRadius: 10,
                },
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  SubmitChangePass();
                }
              }}
            />

            {error ? <p style={{ color: "red" }}>{errorDescription}</p> : null}

            <div
              style={{
                display: "flex",
                alignSelf: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <div
                className="login-page-btn"
                onClick={SubmitChangePass}
                disabled={buttonLoading}
              >
                {buttonLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
