import fetcher from "../helpers/fetcher";

export async function GetGeneralReport({ startDate, endDate }) {
  let path = `/general-report?startDate=${startDate}&endDate=${endDate}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function GetStatisticExportDeclarations(
  page,
  debouncedSearchTerm = "",
  startDate,
  endDate,
  templateId
) {
  let path = `/general-report/statistics?page=${page}&search=${debouncedSearchTerm}&startDate=${startDate}&endDate=${endDate}&templateId=${templateId}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function DownloadExcelFile(startDate, endDate, templateId) {
  let path = `/general-report/download-statistics?startDate=${startDate}&endDate=${endDate}&templateId=${templateId}`;

  let request = await fetcher(path, "GET");

  const content = await request.blob();
  const csvURL = window.URL.createObjectURL(content);

  let tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", "export_declarations.xlsx");
  tempLink.click();

  return content;
}

export async function GetUsersStats(
  page,
  debouncedSearchTerm = "",
  startDate,
  endDate
) {
  let path = `/general-report/users-stats?page=${page}&search=${debouncedSearchTerm}&startDate=${startDate}&endDate=${endDate}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}
